<template>
  <div class="navbar" ref="navbar">
    <div class="logo-title">
      <!-- <img src="~assets/img/logo.png" style="vertical-align: text-bottom;"/>
      <el-divider direction="vertical" style="margin: 0 13px;"></el-divider> -->
      <span>南通市港口信息综合管理平台</span>
    </div>
    <!-- <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar"></hamburger> -->
    <div style="float: left; line-height: 84px">
      <el-divider direction="vertical"></el-divider>
      <router-link to="/screen" tag="a" target="_blank" style="color: #fff">
        <span><img src="~assets/img/screen-video.png" style="vertical-align: middle; margin-right: 10px" />监控大屏</span>
      </router-link>
    </div>
    <avatar />
    <skin />
  </div>
</template>

<script>
import Hamburger from './Hamburger'
import Skin from './Skin/index.vue'
import Avatar from './Avatar'
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  components: {
    Hamburger,
    Skin,
    Avatar,
  },
  computed: {
    ...mapGetters(['sidebar']),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
  },
}
</script>
