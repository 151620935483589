import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 200 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    /*判断是否已登录*/
    if (store.getters.token) {
      config.headers['Authorization'] = getToken()
      return config

    } else {
      /*未登录直接返回配置信息*/
      return config
    }
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const { status, data } = response
    if (status != 200) {
      Message({
        message: data.errorMsg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(data.errorMsg || 'Error'))
    } else {
      if(data.code==401) {
        window.location.href = '/login'
        return Promise.reject(new Error(data.msg || 'Error'))
      }
      return data
    }
  },
  error => {
    Message({
      message: error,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
