import { resetRouter } from '@/router'
import { login, findUserByToken, logout } from '@/api/upms/user'
import { getToken, setToken, removeToken, setLocalStorage, removeLocalStorage } from 'utils/auth'
import { Message } from 'element-ui'
import qs from 'qs'
import logo from 'assets/img/avatar.png'

const state = {
  token: getToken(),
  userInfo: {username:'admin', photo: logo + '?' + new Date()},
  menuResource: [],
  buttonResource: [],
  config: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_MENU_RESOURCE: (state, menuResource) => {
    state.menuResource = menuResource
  },
  SET_BUTTON_RESOURCE: (state, buttonResource) => {
    state.buttonResource = buttonResource
  },
  SET_CONFIG: (state, config) => {
    state.config = config
  }
}

const actions = {
  async login({ commit }, userInfo) {
    const { usercode, password } = userInfo
    let user = new FormData()
    user.append("usercode", usercode)
    user.append("password", password)
    const { code, data, msg } = await login(user)
    if(code===200){
      setToken(data)
      commit('SET_TOKEN', data)
    }else{
      return Promise.reject(msg)
    }
  },
  async findUserByToken({commit}) {
    const { code, data, msg } = await findUserByToken()
    if(code===200){
      let { authorities, configs, menus, user } = data
      setLocalStorage('nt-menuResource', menus)
      commit('SET_MENU_RESOURCE', menus)
      setLocalStorage('nt-config', configs)
      commit('SET_CONFIG', configs)
      setLocalStorage('nt-buttonResource', authorities)
      commit('SET_BUTTON_RESOURCE', authorities)
      setLocalStorage('nt-userInfo', user)
      commit('SET_USER_INFO', user)
    }else{
      Message.error(msg)
    }
  },
  async logout({ commit }) {
    const { code, data, msg } = await logout()
    if(code===200) {
      commit('SET_TOKEN', '')
      removeToken()
      removeLocalStorage('nt-userInfo')
      removeLocalStorage('nt-menuResource')
      removeLocalStorage('nt-buttonResource')
      removeLocalStorage('nt-config')
      commit('SET_USER_INFO', '')
      commit('SET_MENU_RESOURCE', '')
      commit('SET_BUTTON_RESOURCE', '')
      commit('SET_CONFIG', '')
      resetRouter()
    } else {
      Message.error(msg)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
