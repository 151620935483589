import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'

import { outputTree } from "@/utils/common";

router.beforeEach((to, from, next) => {
  //白名单
  if(to.meta.code === 'white'){
    next()
  }else{

    // 1、本地缓存的token是否有值，没有的话，说明用户没有登录过，直接跳转登录页面
    // 2、token值有的，vuex中的用户id存在的话，页面只是路由切换，用next让路由正常流转下去
    // 2、token值有的，vuex中的用户id的值不存在的话，说明页面刷新了，这个时候需要做两个件事情，A：设置vuex中的id的为本地缓存的用户id ，
    //  B：重新请求权限接口，格式化后，动态添加到路由，然后重新用next实现接下来的路由跳转，这里需要注意，请求的权限接口需要用async/wait 改成同步接口方便使用。

    let token = getToken();
    if(token){//判断当前用户是否登录
      if(store.getters.userInfo.usercode){
        next()
      }else{
        //查询用户角色对应的菜单列表
        store.dispatch('user/findUserByToken').then(() => {
          store.dispatch('permission/generateRoutes', { resource: outputTree(store.getters.menuResource, 0, "sort") }).then( accessRoutes => {
            router.addRoutes(accessRoutes)
            next({ ...to, replace: true })
          })
        }).catch((error) => {
          Message.error(error)
        })
      }
    }else{
      next('/login')
    }
  }

})

