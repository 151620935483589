const getters = {
  sidebar: state => state.app.sidebar,
  model: state => state.settings.model,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  menuResource: state => state.user.menuResource,
  buttonResource: state => state.user.buttonResource,
  config: state => state.user.config,
  permission_routes: state => state.permission.routes
}
export default getters
