<template>
  <div class="avatar-container hover-effect">
    <div class="avatar-wrapper">
      <div class="user-avatar">
        <el-dropdown placement="bottom-start" @command="handleCommand">
          <span class="el-dropdown-link">
            <img :src="imgSrc" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="updateUserDialog">
              <span style="color: #222222; vertical-align: middle"><img src="~assets/img/userInfo.jpg" style="padding-right: 20px; vertical-align: middle" />修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <span style="color: #f25a3b"><svg-icon icon-class="logout" />注销登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="user-info">
        {{ userInfo.usercode }}
        <div><svg-icon icon-class="user" />{{ userInfo.nickName }}</div>
      </div>
    </div>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogFormVisiblePwd" width="600px">
      <div slot="title">个人中心</div>
      <el-form :rules="rules" :model="pwdModel" ref="dataFormPwd" label-position="right" label-width="120px">
        <el-form-item label="当前密码：" prop="oldPsw" style="width: 490px">
          <el-input type="password" v-model="pwdModel.oldPsw" placeholder="请输入当前密码" />
        </el-form-item>
        <el-form-item label="新密码：" prop="newPsw" style="width: 490px">
          <el-input type="password" v-model="pwdModel.newPsw" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认密码：" prop="confirmPassword" style="width: 490px">
          <el-input type="password" v-model="pwdModel.confirmPassword" placeholder="请再次输入新密码" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisiblePwd = false"> 取消 </el-button>
        <el-button type="primary" @click="updateInfo()"> 修改 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { updatePsw } from '@/api/upms/user'
import qs from 'qs'
export default {
  name: 'avatar',
  data() {
    let checknewPsw = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入新密码'))
      }
      if (value === this.pwdModel.oldPsw) {
        callback(new Error('新密码和旧密码相同'))
      } else {
        callback()
      }
    }
    let checkConfirmPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入新密码'))
      }
      if (value != this.pwdModel.newPsw) {
        callback(new Error('确认密码和新密码不相同'))
      } else {
        callback()
      }
    }
    return {
      dialogFormVisiblePwd: false,
      pwdModel: {
        oldPsw: '',
        newPsw: '',
        confirmPassword: '',
      },
      rules: {
        oldPsw: [{ required: true, message: '请输入当前密码', trigger: 'blur' }],
        newPsw: [
          { required: true, validator: checknewPsw, trigger: 'blur' },
          { min: 6, trigger: 'blur', message: '密码长度不能小于6位' },
        ],
        confirmPassword: [
          { required: true, validator: checkConfirmPassword, trigger: 'blur' },
          { min: 6, trigger: 'blur', message: '密码长度不能小于6位' },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    encryptMobile() {
      return hideMobile(this.userInfo.mobile)
    },
    imgSrc() {
      return this.userInfo.photo ? this.userInfo.photo : require('assets/img/avatar.png')
    },
  },
  methods: {
    handleCommand(command) {
      if (command === 'logout') this.logout()
      else if (command === 'updateUserDialog') {
        this.dialogFormVisiblePwd = true
        this.$nextTick(() => {
          this.$refs['dataFormPwd'].clearValidate()
        })
      }
    },
    logout() {
      this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    },
    updateInfo() {
      this.$refs['dataFormPwd'].validate((valid) => {
        if (valid) {
          updatePsw(this.pwdModel).then((res) => {
            if (res.code == 200) {
              this.$alert('修改成功,请重新登录！', '提示', {
                confirmButtonText: '确定',
                callback: (action) => {
                  this.logout()
                },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
  },
}
</script>
