<template>
  <div style="float: right; margin-right: 68px;">
    <!-- <el-button type="text" @click="drawer = true" style="color: #fff;">
      <svg-icon icon-class="skin" style="font-size: 32px;vertical-align: middle !important;margin-right: 10px;"/>更换主题
    </el-button> -->
    <el-drawer
      :modal="false"
      :size="300"
      :visible.sync="drawer">
      <div slot="title"><svg-icon icon-class="skin"/> 更换主题</div>
      <div>
        <div class="drawer-title">推荐</div>
        <div class="drawer-item">
          <a :class="model==='default'?'active':''" @click="model='default'"><img src="~assets/img/icon-default.png"/>初始<i class="el-icon-check"/></a>
        </div>
        <div class="drawer-item" style="margin-top: 10px;">
          <a :class="model==='night'?'active':''" @click="model='night'"><img src="~assets/img/icon-night.png"/><i class="el-icon-check"/>夜间</a>
        </div>
      </div>
      <div style="margin-top: 30px;">
        <div class="drawer-title">自定义效果</div>
        <div class="drawer-item">
          <span>头部导航栏颜色：</span>
          <el-color-picker v-model="navBg"></el-color-picker>
        </div>
        <div class="drawer-item">
          <span>侧边导航栏颜色：</span>
          <el-color-picker v-model="sidebarBg"></el-color-picker>
        </div>
        <div class="drawer-item">
          <span>主题颜色：</span>
          <el-color-picker v-model="theme"></el-color-picker>
        </div>
        <!-- <div class="drawer-item">
          <span>主题颜色：</span>
          <theme-picker @change="themeChange" />
        </div> -->
      </div>
    </el-drawer>
  </div>
</template>
<style lang="scss" scoped>
  .drawer-title {
    font-size: 12px;
    font-weight: 400;
    color: #a8b0dd;
    padding-bottom: 5px;
    margin-bottom: 14px;
    border-bottom: 1px solid #d6dbf9;
  }
  .drawer-item {
    line-height: 48px;
    font-size: 16px;
    color: inherit;
    a {
      display: block;
      i {
        display: none;
        float: right;
        line-height: inherit;
        // color: $theme;
      }
      &.active i{
        display: inline-block;
      }
    }
    img {
      vertical-align: middle;
      margin-right: 16px;
    }
    .theme-picker {
      vertical-align: middle;
      float: right;
      height: 48px;
      padding: 10px 0;
    }
    .el-color-picker {
      vertical-align: middle;
      padding: 10px 0;
      float: right;
    }
    /deep/.el-color-picker__trigger {
      height: 28px !important;
      width: 28px !important;
      padding: 0;
      border: none;
      .el-color-picker__icon {
        color: transparent;
      }
    }
  }
</style>
<script>
import ThemePicker from './ThemePicker'
export default {
  name: 'skin',
  components: {
    ThemePicker
  },
  data() {
    return {
      drawer: false
    }
  },  
  computed:{
    model: {
      get() {
        return this.$store.state.settings.model
      },
      set(val) {
        this.$store.dispatch('settings/changeModel', val)
      }
    },
    sidebarBg: {
      get() {
        return this.$store.state.settings.sidebarBg
      },
      set(val) {
        this.$store.dispatch('settings/changeSidebarBg', val)
      }
    },
    navBg: {
      get() {
        return this.$store.state.settings.navBg
      },
      set(val) {
        this.$store.dispatch('settings/changeNavBg', val)
      }
    },
    theme: {
      get() {
        return this.$store.state.settings.theme
      },
      set(val) {
        this.$store.dispatch('settings/changeTheme', val)
      }
    }    
  },
  created() {
  },
  methods: {
    // themeChange(val) {
    //   this.$store.dispatch('settings/changeSetting', {
    //     key: 'theme',
    //     value: val
    //   })
    // }
  },
  watch: {
    model: {
      handler(val) {
        window.document.getElementsByTagName("body")[0].setAttribute('data-theme', val)
      },
      immediate: true
    },
    navBg: {
      handler(val) {
        this.$nextTick(() => {
          document.getElementsByClassName("navbar")[0].style.backgroundColor = val
        })
      },
      immediate: true
    },
    sidebarBg: {
      handler(val) {
        this.$nextTick(() => {
          document.getElementsByClassName("sidebar-container")[0].style.backgroundColor = val
        })
      },
      immediate: true
    },
    theme: {
      handler(val) {
        this.$nextTick(() => {
          document.getElementsByTagName('body')[0].style.setProperty('--theme', val);
          // document.getElementsByClassName("sidebar-container")[0].style.backgroundColor = val
        })
      },
      immediate: true
    }
  }
}
</script>
