import variables from '@/styles/element-variables.scss'
import customVariables from '@/styles/variables.scss'
import { setLocalStorage, getLocalStorage } from 'utils/auth'

const state = {
  theme: getLocalStorage('theme') || variables.theme,
  model: getLocalStorage('model') || 'default',
  sidebarBg: getLocalStorage('sidebarBg'),
  navBg: getLocalStorage('navBg')
}

const mutations = {
  CHANGE_THEME: (state, theme) => {
    state.theme = theme
    setLocalStorage('theme', theme)
  },
  CHANGE_MODEL: (state, model) => {
    state.model = model
    setLocalStorage('model', model)
    setLocalStorage('sidebarBg',customVariables['sidebar-'+model])    
    setLocalStorage('navBg',customVariables['nav-'+model])
  },
  CHANGE_SIDEBAR_BG: (state, sidebarBg) => {
    state.sidebarBg = sidebarBg
    setLocalStorage('sidebarBg',sidebarBg)
  },
  CHANGE_NAV_BG: (state, navBg) => {
    state.navBg = navBg
    setLocalStorage('navBg',navBg)
  }
}

const actions = {
  changeTheme({ commit }, data) {
    commit('CHANGE_THEME', data)
  },
  changeModel({ commit }, data) {
    commit('CHANGE_MODEL', data)
  },
  changeSidebarBg({ commit }, data) {
    commit('CHANGE_SIDEBAR_BG', data)
  },
  changeNavBg({ commit }, data) {
    commit('CHANGE_NAV_BG', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

