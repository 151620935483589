import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import _ from 'lodash'

import ElementUI from 'element-ui'

import './permission'

import './styles/element-variables.scss'
import './styles/index.scss' // global css

import './icons'

Vue.use(ElementUI)

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
})

Array.prototype.remove = function(val) {
  var index = this.indexOf(val)
  if (index > -1) {
      this.splice(index, 1)
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
