<template>
  <div style="height: 100%">
    <navbar />
    <div :class="classObj" class="app-wrapper">
      <sidebar class="sidebar-container" />
      <app-main class="main-container"></app-main>
    </div>
  </div>
</template>



<script>
import { Sidebar, Navbar, AppMain } from './components'
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  components: {
    Sidebar,
    Navbar,
    AppMain,
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.app-wrapper {
  @include clearfix;
  height: 100%;
  padding-top: 80px;
  width: 100%;
  overflow: hidden;

  .sidebar-container {
    background: url('~assets/img/asidebg.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-color: transparent !important;
  }
}
</style>
