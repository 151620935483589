import Cookies from 'js-cookie'

const TokenKey = 'nt-token'

export function getCookie(key) {
  return Cookies.get(key)
}
export function setCookie(key, value) {
  return Cookies.set(key, value)
}
export function removeCookie(key) {
  return Cookies.remove(key)
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setLocalStorage(key, value) {
  return localStorage.setItem(key, JSON.stringify(value))
}
export function getLocalStorage(key) {
  return localStorage.getItem(key) && JSON.parse(localStorage.getItem(key))
}
export function removeLocalStorage(key) {
  return localStorage.removeItem(key)
}

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}
