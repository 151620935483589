import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const Login = () => import('views/login')

const Screen = () => import('views/screen')
const Home = () => import('views/dashboard/index')
const AreaDust = () => import('views/dust/areaDust')
const RealtimeData = () => import('views/dust/realtimeData')
const Diagnosis = () => import('views/dust/diagnosis')
const Cctv = () => import('views/dust/cctv')
const Area = () => import('views/shorePower/area')
const Use = () => import('views/shorePower/use')
const Statistics = () => import('views/shorePower/statistics')
const Menu = () => import('views/upms/menus')
const Role = () => import('views/upms/role')
const User = () => import('views/upms/user')
const Company = () => import('views/upms/company')
const CompanyArea = () => import('views/company/area')
const CompanyQuery = () => import('views/company/companyQuery')
const Equipment = () => import('views/company/equipment')
const EquipmentQuery = () => import('views/company/equipmentQuery')
const Basic = () => import('views/company/basic')
// const Whole = () => import('views/statistics/whole')
const ElectricityStatistics = () => import('views/statistics/electricityStatistics')

const BusinessType = () => import('views/app/businessType')
const Business = () => import('views/app/business')
const Industry = () => import('views/app/industry')
const Personal = () => import('views/app/personal')
const AlarmDescribe = () => import('views/app/alarmDescribe')
const AppUser = () => import('views/app/user')

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'company/area'
  }
]

export const constantRoutes = [
  {
    path: '/login',
    component: Login,
    hidden: true,
    name: '登录',
    meta: {
      code: 'white',
      title: '登录'
    }
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  // {
  //   path: '/videoIframe',
  //   component: VideoIframe,
  //   hidden: true,
  //   meta: {
  //     code: 'white',
  //     title: '黄山市大气精细化管理系统'
  //   }
  // },
  {
    path: '/screen',
    component: Screen,
    hidden: true,
    name: '大屏',
    meta: {
      code: 'white',
      title: '大屏'
    }
  }
]

export const routerMap = {
  Home,
  AreaDust,
  RealtimeData,
  Diagnosis,
  Cctv,
  Area,
  Use,
  Statistics,
  Company,
  CompanyArea,
  Equipment,
  // Whole,
  ElectricityStatistics,
  Basic,
  Menu,
  Role,
  User,
  CompanyQuery,
  EquipmentQuery,

  Business,
  BusinessType,
  Industry,
  Personal,
  AlarmDescribe,
  AppUser,
}

const createRouter = () => new VueRouter({
  mode: 'history', // require service support
  // scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = createRouter()

export default router
