import { asyncRoutes, constantRoutes, routerMap } from '@/router'
import Layout from '@/layout'

const state = {
  routes: asyncRoutes,
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

function createRoutes(resource) {
  const res = []
  resource.forEach((item) => {
    let { url, component, menuName, icon, resType, target, parentId } = item
    let data = {
      path: url,
      name: menuName,
      icon,
      meta: { icon: icon, title: menuName }
    }
    if(resType === 0){
      data.component = Layout
    }else{
      if(parentId===0 && target===0) {
        data= {
          path: url,
          component: Layout,
          children: [
            {
              path: url,
              component: routerMap[component],
              name: menuName,
              meta: { icon: icon, title: menuName }
            }
          ]
        }
      } else {
        if(target === 0) data.component = routerMap[component]
      }
    }

    if(item.children){
      data.children = createRoutes(item.children)
    }
    res.push(data)
  })
  return res
}

const actions = {
  generateRoutes({ commit },{ resource }) {
    return new Promise(resolve => {
      let accessedRoutes = createRoutes(resource)
      accessedRoutes = asyncRoutes.concat(accessedRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
