import { Message } from 'element-ui'

export const requireSDK = (jsApi, ak, host, callback) => new Promise(((resolve, reject) => {
  const head = document.head;
  let dom = head.querySelector(`[src*="${host}"]`);
  if (dom) {
    resolve(1);
    return;
  }
  window.HOST_TYPE = '2';
  dom = document.createElement('script');
  dom.type = 'text/javascript';
  dom.src = `${jsApi}&key=${ak}${callback && `&callback=${callback}`}`;
  dom.onerror = reject;
  dom.onload = resolve;
  head.appendChild(dom);
}));

/**
 * 字符串是否为空
 * @param  {obj} source 时间对象
 */
export function isEmpty(obj) {
    if (typeof obj == "undefined" || obj == null || obj == "") {
        return true
    } else {
        return false
    }
}

export function hexToRgba (hex, opacity) {
  return hex && hex.replace(/\s+/g, '').length === 7 ? 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' +
    parseInt('0x' + hex.slice(3, 5)) + ',' +
    parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')' : ''
}

export function preMonth(date) {
  if(date.getMonth()==0){
    date.setFullYear(date.getFullYear()-1)
    date.setMonth(11)
  }else{
    date.setMonth(date.getMonth()-1)
  }
  date.setDate(1)
  return new Date(date.getTime()-date.getTime()%(1000*60*60*24))
}

export function nextMonth(date) {
  if(date.getMonth()==11){
    date.setFullYear(date.getFullYear()+1)
    date.setMonth(0)
  }else{
    date.setMonth(date.getMonth()+1)
  }
  date.setDate(1)
  return new Date(date.getTime()-date.getTime()%(1000*60*60*24))
}

export function getLevel(index,value) {
  if(value=='-') {
    return {
      color: '#eee',
      level: 0
    }
  }
  let colors = ["#00FF00", "#FFFF00", "#FF7E00", "#FF0000", "#99004C", "#7E0023"]
  let aqi = [50,100,150,200,300]
  let pm25 = [35,75,115,150,250]
  let pm10 = [50,150,250,350,420]
  let so2 = [50,150,475,800,1600]
  let no2 = [40,80,180,280,565]
  let co = [2,4,14,24,36]
  let o3 = [160,200,300,400,800]
  let level, color = ''
  if(index=="aqi"){
    var num = 0;
    for (var i = 0; i < aqi.length; i++) {
      if(value>aqi[i]){
        num++;
      }
    }
    color = colors[num]
    level = num+1
  }

  if(index=="pm2_5"){
    var num = 0;
    for (var i = 0; i < pm25.length; i++) {
      if(value>pm25[i]){
        num++
      }
    }
    color = colors[num]
    level = num+1
  }

  if(index=="pm10"){
    var num = 0;
    for (var i = 0; i < pm10.length; i++) {
      if(value>pm10[i]){
        num++
      }
    }
    color = colors[num]
    level = num+1
  }

  if(index=="so2"){
    var num = 0
    for (var i = 0; i < so2.length; i++) {
      if(value>so2[i]){
        num++
      }
    }
    color = colors[num]
    level = num+1
  }

  if(index=="no2"){
    var num = 0;
    for (var i = 0; i < no2.length; i++) {
      if(value>no2[i]){
        num++
      }
    }
    color = colors[num]
    level = num+1
  }

  if(index=="co"){
    var num = 0;
    for (var i = 0; i < co.length; i++) {
      if(value>co[i]){
        num++
      }
    }
    color = colors[num]
    level = num+1
  }

  if(index=="o3"){
    var num = 0;
    for (var i = 0; i < o3.length; i++) {
      if(value>o3[i]){
        num++
      }
    }
    color = colors[num]
    level = num+1

  }
  return {
    color,
    level
  }
}

export function getWindDirection(value) {
  if(value||value==0){
    if (value >= 0 && value <= 22.5) {
      return "北风"
    } else if (value >= 22.6 && value <= 67.5) {
      return "东北风"
    } else if (value >= 67.6 && value <= 112.5) {
      return "东风"
    } else if (value >= 112.6 && value <= 157.5) {
      return "东南风"
    } else if (value >= 157.6 && value <= 202.5) {
      return "南风"
    } else if (value >= 202.6 && value <= 247.5) {
      return "西南风"
    } else if (value >= 247.6 && value <= 292.5) {
      return "西风"
    } else if (value >= 292.6 && value <= 337.5) {
      return "西北风"
    } else if (value >= 337.6 && value <= 360.0) {
      return "北风"
    }
  } else {
    return '-'
  }
}

/**
 * 格式化时间
 * @param  {Datetime} source 时间对象
 * @param  {String} format 格式
 * @return {String}        格式化过后的时间
 */
export function formatDate(source, format){
  const o = {
    'Y+': source.getFullYear(), // 年
    'M+': source.getMonth() + 1, // 月份
    'd+': source.getDate(), // 日
    'H+': source.getHours(), // 小时
    'm+': source.getMinutes(), // 分
    's+': source.getSeconds(), // 秒
    'q+': Math.floor((source.getMonth() + 3) / 3), // 季度
    'f+': source.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (source.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return format
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
 export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/*
* 生成树
* params:{arr:数据列表，parentId:根级树的parentId,sortName:用于同级树排序}
* */
export function outputTree(arr,parentId,sortName){
  var result = [] , temp = []
  for(var i in arr){
    if(arr[i].parentId == parentId){
      result.push(arr[i])
      temp = outputTree(arr,arr[i].id,sortName)
      if(temp.length>0){
        //对同级树排序
        if (sortName) {
          arr[i].children = temp.sort(function(a,b){
            return a[sortName] - b[sortName]
          });
        }else{
          arr[i].children = temp
        }

      }
    }
  }
  return result
}

/*
 * 判断数据类型
 * 输入：obj:参数，type:数据类型
 * 输出：Boolean
 * */
export function isDataType(obj,type) {
  let a = Object.prototype.toString.call(obj)
  let r = ''
  switch (a) {
    case '[object Object]':
      r = 'object'
      break
    case '[object Array]':
      r = 'array'
      break
    case '[object Boolean]':
      r = 'boolean';
      break
    case '[object Null]':
      r = 'null'
      break
    case '[object Undefined]':
      r = 'undefined'
      break
    case '[object Number]':
      r = 'number'
      break
    case '[object String]':
      r = 'string'
      break
  }
  return r === (type.toLowerCase())
}


/*对象数组根据某一属性去重*/
export function uniqueArr(arr,attr) {
  let obj = {}
  return arr.reduce((item,next)=>{
    obj[next[attr]] ? '' : obj[next[attr]] = true && item.push(next)
    return item
  },[])
}
 // 饼状颜色表
export const getColors = ['#4EA1FF','#A1FF4E','#FF4E83','#AC4EFF','#4EFFAC','#FFCA4E','#FF4EDC', '#4EDCFF', '#BEFF4E', '#FF4E66','#544EFF',  '#4EFF54', '#FFAC4E','#CA4EFF', '#4EFFCA']

/**
 *  限制上传图片大小
 * @param width 限制宽度
 * @param height 限制高度
 * @param file
 */
export const uploadSize = (width,height,file) => {
  return  new Promise(function (resolve, reject) {
    let _URL = window.URL || window.webkitURL
    let img = new Image()
    img.onload = function () {
      let valid = (img.width/img.height) - (width/height)

      if(Math.abs(valid) <= 0.05){//
        resolve()
      }else{
        reject()
      }
    }
    img.src = _URL.createObjectURL(file)
  }).then(() => {
    return file
  }, () => {
    Message.warning({
      message: `上传文件的图片大小不合符标准,宽高度比${width} : ${height}。`,
      btn: false
    })
    return Promise.reject()
  });
}
export function downloading(res,title) {
  try {
    if (!res) {
      return
    }

    let headers = res.headers;
    let blob = new Blob([res.data], {
      type: headers['content-type']
    })

    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)

    if (!title) {
      const fileName = headers['content-disposition']
      title = fileName.includes('filename=') ? fileName.split('=')[1] : '下载的表单文件'
    }
    link.download = decodeURI(title)
    link.click()
  } catch (e) {
    Message({
      message: '导出错误',
      type: 'error',
      duration: 5 * 1000
    })
  }

}


export function uuid() {
  var s = []
  var hexDigits = "0123456789abcdef"
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = "4"  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-"

  var uuid = s.join("")
  return uuid
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr =atob(arr[1])
  var n = bstr.length
  var u8arr =new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {type: mime })
}

export function blobToFile(blob, fileName){
  blob.lastModifiedDate =new Date()
  blob.name = fileName
  return blob
}

export function dataURLtoFile(dataurl, filename){
  var arr = dataurl.split(','),// mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
  while(n--){
    u8arr[n] = bstr.charCodeAt(n)
  }
  var blob = dataURLtoBlob(dataurl)
  return blobToFile(blob, filename)
}


// 替换邮箱字符
export function hideEmail(email) {
  if (String(email).indexOf('@') > 0) {
    var str = email.split('@'),
      _s = ''
    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*'
      }
    }
    var new_email = str[0].substr(0, 3) + _s + '@' + str[1]
  }
  return new_email
}

// 替换手机字符
export function hideMobile(mobile) {
  let new_mobile
  if (mobile.length > 7) {
    new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7)
  }
  return new_mobile
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
