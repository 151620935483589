import request from '@/utils/request'

export function login(data) {
  return request({
    url: 'login',
    method: 'post',
    data
  })
}

//通过token查询用户信息
export function findUserByToken() {
  return request({
    url: '/findUserByToken',
    method: 'get'
  })
}

//登出
export function logout() {
  return request({
    url: '/logout',
    method: 'get'
  })
}

//修改密码
export function updatePsw(data) {
  return request({
    url: '/upms/updatePsw',
    method: 'post',
    data
  })
}

export function getOrgsAndRoles() {
  return request({
    url: '/riverPort/upms/user/getOrgsAndRoles',
    method: 'get'
  })
}

export function getPage(data) {
  return request({
    url: '/riverPort/upms/user/page?' + data,
    method: 'get'
  })
}

export function save(data) {
  return request({
    url: '/riverPort/upms/user/save',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/riverPort/upms/user/update',
    method: 'post',
    data
  })
}

//重置密码
export function resetPassword(data) {
  return request({
    url: '/riverPort/upms/user/resetPassword/' + data,
    method: 'get'
  })
}

//更新用户状态
export function updateStatus(usercode, status) {
  return request({
    url: '/riverPort/upms/user/updateStatus/' + usercode + '/' + status,
    method: 'get'
  })
}

//通过主键删除
export function remove(data) {
  return request({
    url: '/riverPort/upms/user/delete',
    method: 'post',
    data
  })
}

//查询有权限的和没权限的区县
export function getAreaPermission(data) {
  return request({
    url: '/riverPort/upms/user/getAreaPermission?'+data,
    method: 'get'
  })
}

//查询有权限的和没权限的企业
export function getCompanyPermission(data) {
  return request({
    url: '/riverPort/upms/user/getCompanyPermission?'+data,
    method: 'get'
  })
}

//给用户赋值权限-区县
export function updateUserPrivilegeArea(data) {
  return request({
    url: '/riverPort/upms/user/updateUserPrivilegeArea',
    method: 'post',
    data
  })
}

//给用户赋值权限-企业
export function updateUserPrivilegeCompany(data) {
  return request({
    url: '/riverPort/upms/user/updateUserPrivilegeCompany',
    method: 'post',
    data
  })
}